import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

interface Review {
    name: string;
    rating: number;
    text: string;
    timestamp: number;
    field_name?: string;
    profile_picture: string;
}

interface GoogleReviewsProps {
    reviews: Review[];
    googlePlaceId?: string;
}

const GoogleReviews: React.FC<GoogleReviewsProps> = ({ reviews, googlePlaceId }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 3;

    const goToNext = () => {
        setCurrentIndex((prevIndex) => 
            (prevIndex + itemsPerPage >= reviews.length) ? 0 : prevIndex + itemsPerPage
        );
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => 
            (prevIndex - itemsPerPage < 0) ? Math.max(0, reviews.length - itemsPerPage) : prevIndex - itemsPerPage
        );
    };

    const visibleReviews = reviews.slice(currentIndex, currentIndex + itemsPerPage);

    const getGoogleReviewUrl = (placeId: string) => {
        return `https://search.google.com/local/writereview?placeid=${placeId}`;
    };

    return (
        <div className="w-full bg-ivory py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-12">
                    <div className="flex items-center justify-center mb-4">
                        <img src="/images/google-g.svg" alt="Google" className="w-6 h-6 mr-2" />
                        <h2 className="text-3xl font-bold text-gray-900">Google Reviews</h2>
                    </div>
                </div>

                {/* Reviews Carousel */}
                <div className="relative px-4 sm:px-8 md:px-12">
                    <div className="flex justify-between items-center">
                        {reviews.length > itemsPerPage && (
                            <button
                                onClick={goToPrevious}
                                className="absolute left-0 z-10 md:-translate-x-12 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50 transition-all duration-200"
                                aria-label="Previous reviews"
                            >
                                <ChevronLeft className="w-6 h-6 text-gray-600" />
                            </button>
                        )}

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 w-full">
                            {visibleReviews.map((review, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-lg shadow-lg p-6 transition-all duration-300 hover:shadow-xl relative overflow-hidden"
                                >
                                    <div className="flex items-center mb-4">
                                        <img
                                            src={review.profile_picture}
                                            alt={review.name}
                                            className="w-12 h-12 rounded-full mr-4"
                                        />
                                        <div>
                                            <h3 className="font-semibold text-gray-900">{review.name}</h3>
                                            <div className="flex items-center">
                                                {[...Array(5)].map((_, i) => (
                                                    <Star
                                                        key={i}
                                                        className={`w-4 h-4 ${
                                                            i < review.rating
                                                                ? 'text-yellow-400 fill-current'
                                                                : 'text-gray-300'
                                                        }`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <p className="text-gray-600 transition-all duration-300 line-clamp-4 hover:line-clamp-none pb-8">
                                            {review.text}
                                        </p>
                                        <div 
                                            className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white via-white/90 to-transparent pointer-events-none"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="flex justify-between text-sm text-gray-500 mt-2">
                                        {review.field_name && <div className="font-semibold">{review.field_name}</div>}
                                        <div>{review.timestamp}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {reviews.length > itemsPerPage && (
                            <button
                                onClick={goToNext}
                                className="absolute right-0 z-10 md:translate-x-12 bg-white rounded-full p-2 shadow-lg hover:bg-gray-50 transition-all duration-200"
                                aria-label="Next reviews"
                            >
                                <ChevronRight className="w-6 h-6 text-gray-600" />
                            </button>
                        )}
                    </div>
                </div>

                {/* Review Button */}
                {googlePlaceId && (
                    <div className="mt-12 text-center">
                        <a
                            href={getGoogleReviewUrl(googlePlaceId)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand hover:bg-brand/90 transition-colors duration-200"
                        >
                            <img src="/images/google-g.svg" alt="Google" className="w-5 h-5 mr-2" />
                            Review Us on Google
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GoogleReviews; 